import heroImage from "../assets/images/pbsd.png";

export default function Home() {
  return (
    <>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div id="home" className="bg-white flex flex-col lg:h-[48rem]">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto my-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto order-2 lg:order-1 place-self-center lg:col-span-7">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              Paderbornder Sicherheitsdienst
            </h1>
            <h3 className="text-xl font-bold tracking-tight text-gray-600 sm:text-2xl my-2">
              Wir sind bereit, um Ihre Sicherheit zu sorgen
            </h3>
            <p className="my-6 text-sm font-semibold leading-8 text-gray-600">
              Verlassen Sie sich auf unsere Expertise und Erfahrung, um ein
              Höchstmaß an Schutz zu gewährleisten. Kontaktieren Sie uns jetzt,
              damit wir gemeinsam für Ihre Sicherheit sorgen können.
            </p>
            <div className="flex flex-col sm:flex-row">
              <a
                href="#contact-us"
                className="m-2 rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-lg hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Kontaktieren Sie uns
              </a>
              <a
                href="#about-us"
                className="m-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-black shadow-lg hover:bg-slate-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              >
                Mehr erfahren <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div
            className="h-[30rem] md:h-[35rem] lg:h-[25rem] lg:mt-0 order-1 lg:order-2 mb-16 lg:mb-0 lg:col-span-5 lg:flex rounded-xl shadow-2xl bg-no-repeat bg-cover bg-center"
            style={{ backgroundImage: `url(${heroImage})` }}
          ></div>
        </div>
      </div>
    </>
  );
}
