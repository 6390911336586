export default function Masthead() {
  const mastheadInformation = `
  <!-- 
    @Author:   Shayan Mahtabi
    @Email:    info@shayanmhtb.com
    @Website:  https://shayanmhtb.com

    @Customer: paderborner sicherheitsdienst
    @Date:     2023
    @Version:  2.0.0 
  -->
  `;

  window.onload = () => {
    document.documentElement.insertAdjacentHTML(
      "beforeend",
      mastheadInformation
    );
  };
}
