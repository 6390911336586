import { navigationItems } from "../data/Navigation";

export default function Footer() {
  const navigation = navigationItems;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-white rounded-lg shadow m-4">
      <div className="w-full mx-auto p-4 flex flex-col md:flex-row items-center md:justify-between">
        <span className="flex flex-row text-xs lg:text-sm text-gray-500 sm:text-center ">
          © {currentYear}{" "}
          <a href="http://pb-sd.de" target="_self" className="hover:underline flex flex-row">
            <div className="hidden lg:block">&nbsp;Paderbornder&nbsp;</div> <div className="block lg:hidden">&nbsp;PB&nbsp;</div> 
            <div className="hidden md:block">&nbsp;Sicherheitsdienst™&nbsp;</div> <div className="block md:hidden">&nbsp;SD™&nbsp;</div>
          </a>
          . All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-xs lg:text-sm font-medium text-gray-500  sm:mt-0">
          {navigation.map((item) => {
            return (
              <li key={item.name}>
                <a href={item.href} className="hover:underline me-1 md:me-2 lg:me-6">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
}
