import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";

import { MapPinIcon, PhoneIcon, InboxIcon } from "@heroicons/react/24/outline";

export default function Contact() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div id="contact-us" className="isolate bg-white py-8 sm:py-28">
      <div
        className="mx-auto max-w-2xl text-center"
        data-aos="zoom-in"
        data-aos-delay="50"
        data-aos-offset="0"
      >
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Kontaktieren Sie uns
        </h2>
        <p className="mt-2 px-16 text-lg leading-8 text-gray-600">
          Wir freuen uns darauf, Sie kennenzulernen und Ihre
          Sicherheitsanforderungen zu besprechen
        </p>
      </div>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 py-8">
        <div
          className="col-span-12 xl:col-span-9 xl:rounded-r-2xl"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-offset="0"
        >
          <iframe
            title="location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2471.6931050314947!2d8.775264977075526!3d51.72035699512328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ba4cef55029a7d%3A0x772307e5d092613a!2sLange%20Wenne%2012%2C%2033100%20Paderborn!5e0!3m2!1sen!2sde!4v1703977276657!5m2!1sen!2sde"
            width="100%"
            height="500"
            style={{ border: 0 }}
            allowFullScreen={true}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-r-2xl"
          ></iframe>
        </div>
        <div
          className="col-span-12 xl:col-span-3 xl:col-start-10 bg-blue-600 xl:rounded-l-2xl p-16 xl:p-0"
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-offset="0"
        >
          <div className="flex flex-col h-full justify-center p-4 text-center xl:text-left text-white">
            <h1 className="text-xl font-bold sm:font-normal sm:text-4xl xl:text-2xl mt-auto mb-4">
              Kontakt Informationen
            </h1>
            <ul className="list-none mb-auto mx-auto xl:mx-0 flex flex-col items-center xl:items-start">
              <li>
                <a
                  href="https://maps.app.goo.gl/K2nbkqr88tSpRPbe7"
                  className="flex flex-row items-center px-2 py-1 hover:underline text-sm sm:text-lg xl:text-base"
                >
                  <MapPinIcon width={16} height={16} className="mr-2" />
                  Lange Wenne 12, 33100 Paderborb
                </a>
              </li>
              <li>
                <a
                  href="tel:+4952514027591"
                  className="flex flex-row items-center px-2 py-1 hover:underline text-sm sm:text-lg xl:text-base"
                >
                  <PhoneIcon width={16} height={16} className="mr-2" />
                  +49 (5251) 4027591
                </a>
              </li>
              <li>
                <a
                  href="mailto:info@pb-sd.de"
                  className="flex flex-row items-center px-2 py-1 hover:underline text-sm sm:text-lg xl:text-base"
                >
                  <InboxIcon width={16} height={16} className="mr-2" />
                  info@pb-sd.de
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
