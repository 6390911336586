import {
  MagnifyingGlassCircleIcon,
  UserCircleIcon,
  GlobeAltIcon,
} from "@heroicons/react/20/solid";

export const services = [
  {
    key: 1,
    aosDelay: 100,
    name: "Objektbewachung",
    description:
      "Zuverlässige Sicherheitskräfte schützen Ihre Räumlichkeiten rund um die Uhr.",
    icon: MagnifyingGlassCircleIcon,
  },
  {
    key: 2,
    aosDelay: 200,
    name: "Personenschutz",
    description:
      "Professioneller Schutz für Einzelpersonen und Personengruppen.",
    icon: UserCircleIcon,
  },
  {
    key: 3,
    aosDelay: 300,
    name: "Veranstaltungssicherheit",
    description: "Sicherheitslösungen für Veranstaltungen jeder Art und Größe.",
    icon: GlobeAltIcon,
  },
];
